import React from 'react'
import TalkSuccess from '../../components/talk/success'
import SEO from '../../components/seo'

const Success = () => (
  <>
    <SEO title="Contact" />
    <TalkSuccess />
  </>
)

export default Success
