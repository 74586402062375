import React from 'react'
import styled from 'styled-components'
import ContactImage from './images/contact-laptop'

const Success = () => {
  return (
    <PageContainer>
      <TextContainer>
        Thanks for reaching out. We'll get back to you shortly!.
      </TextContainer>
      <ImageContainer>
        <ContactImage />
      </ImageContainer>
    </PageContainer>
  )
}

export default Success

const PageContainer = styled.div`
  text-align: center;
  background: black;
  color: white;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0px auto;
`

const ImageContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  padding-top: 10px;
  max-width: 600px;
`

const TextContainer = styled.div`
  font-size: 16px;
  width: 75%;
  margin: auto;
  padding: 25px 0px;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  line-height: 1.3;

  @media screen and (min-width: 375px) {
    font-size: calc(16px + (35 - 16) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 35px;
  }
`
